import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalSizes, MotifModal, MotifModalRef } from '@ey-xd/ng-motif';
import { BehaviorSubject, firstValueFrom, timer } from 'rxjs';
import { environment } from 'src/assets/client-config';
import { RenewSessionComponent } from './components/renew-session/renew-session.component';
import { MsalService } from '@azure/msal-angular';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
   // active session interval
    activeSession:any;
    //Declared as Observables 
    public inactiveSessionExpiry = new BehaviorSubject<number>(0);
    public sessionId = new BehaviorSubject<string>(null);
    //private timer variables for 
    private mandatorySecondsTimer: number = 0;
    private inactivitySecondsTimer: number = 0;
    private activeTimeRemaining: number = 0;

    public modalRef?: MotifModalRef<any>;
    constructor(private motifModal: MotifModal, private http: HttpClient, private msal: MsalService) { }

    //set new session on app load
    startSession() {
        return this.http.post<any>(environment.apiUrl + environment.loginUrl, {}).subscribe(result => {
            if (result) {
                this.sessionId.next(result.sessionId);
                this.inactiveSessionExpiry.next(result.inActiveSessionExpiryInSeconds);   // reused whenever the active timer is reset due to api call or renew session
                this.inactivitySecondsTimer = result.inActiveSessionExpiryInSeconds;     // to set active timer value
                this.mandatorySecondsTimer = result.mandatorySessionTimeOutInSeconds;    // to set mandatory timer value
                this.startActiveSessionTimer();
                this.startMandatorySessionTimer();
            }
        });
    }
    //renew session 
    renewSession() {
        return this.http.post<any>(environment.apiUrl + environment.renewSession, null).subscribe(result => {
            if (result) {
                clearInterval(this.activeSession);
                this.startActiveSessionTimer();
            }
        });
    }
    //update session on api call activities
    updateSession(){
        return this.http.get<any>(environment.apiUrl + environment.updateSession).subscribe(result=>{
                clearInterval(this.activeSession);
                this.startActiveSessionTimer();
        });
    }
    //logout session
    logoutSession() {
        return this.http.get(environment.apiUrl + environment.logout, { responseType: 'text' }).subscribe(result => {
            if (result) {
                clearInterval(this.activeSession);
                this.msal.logout();
                this.clearSessionStorage('questionLibraryFilter');
            }
        });
    }
    //to start active session timer
    startActiveSessionTimer() {
        this.activeTimeRemaining = this.inactivitySecondsTimer;
        this.activeSession = setInterval(() => {
            this.activeTimeRemaining--;
            this.inactiveSessionExpiry.next(this.activeTimeRemaining);
            //to show renew session popup
            if (this.activeTimeRemaining == 60) {
                const dialogConfig = { size: ModalSizes.L, hasBackdrop: true, disableClose: true }
                this.modalRef = this.motifModal.open(RenewSessionComponent, dialogConfig);
            }
            if (this.activeTimeRemaining == 0) {
                this.logoutSession();
                this.motifModal?.closeAll();
                clearInterval(this.activeSession)
            }
        }, 1000)

    }
    //to start mandatory session timer
    startMandatorySessionTimer() {
        const mandatorySession = timer(200, 1200);
        mandatorySession.subscribe(val => {
            const mandatoryTimeRemaining = this.mandatorySecondsTimer - val;
            if (mandatoryTimeRemaining == 0) {
                this.logoutSession();
                this.motifModal?.closeAll();
            }
        });
    }

    //security token
    async getSecurityToken(): Promise<any>{
        const token = await firstValueFrom(
            this.http.post(environment.apiUrl + '/api/Token/sk', null, { responseType: 'text' })
        );
        return token;    
    }

    setSessionStorage(key: string, value: any) {
        sessionStorage[key] = JSON.stringify(value);
    }

    getSessionStorage(key: string) {
        return JSON.parse(sessionStorage[key] || null);
    }

    clearSessionStorage(key: string) {
        sessionStorage.removeItem(key);
    }
}