<motif-theme variant='light'></motif-theme>
<app-nav *ngIf="loginDisplay && isLogin; else splash" id="top"></app-nav>
<div class="app-container">
    <router-outlet></router-outlet>
</div>
<app-toast></app-toast>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple">
</ngx-spinner>

<ng-template #splash>
  <app-motif-progress-loader></app-motif-progress-loader>
</ng-template>
<app-data-loader *ngIf="isLoading"></app-data-loader>
 