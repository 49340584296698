import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { MsalInterceptorConfiguration, MsalGuardConfiguration } from '@azure/msal-angular';
import {environment} from "src/assets/client-config";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
    
    return new PublicClientApplication({
      auth: environment.msalConfig,
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        claimsBasedCachingEnabled: true
      },
      system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false
        }
      }
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set("*", [`api://${environment.msalConfig.apiClientId}/user_impersonation`]);
    protectedResourceMap.set(environment.msalConfig.profileUrl, ['user.read']);
    protectedResourceMap.set(environment.apiUrl, [`api://${environment.msalConfig.apiClientId}/access_as_user`]);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['user.read']
        },
        loginFailedRoute: '/logout',
    };
}
