import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipConfig } from 'ngx-bootstrap/tooltip';
import { HttpClient, HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { ModalModule } from 'ngx-bootstrap/modal';
// import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { QuillModule } from 'ngx-quill';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { FaasHttpInterceptor } from './_services/faashttp.interceptor';
import { HomeComponent } from './home/home.component';
import { ErrorInterceptorProvider } from './_services/error.interceptor';
import { DashboardComponent } from './readiness/dashboard/dashboard.component';
import { ReportsComponent } from './readiness/reports/reports.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from './routes';
import { AuthGuard } from './session_management/auth.guard';
import { EngagementGuard } from './_guard/engagement.guard';
import { LoggingService } from './_services/logging.service';
import { CreateWorkspaceComponent } from './workspace/create-workspace/create-workspace.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FinancialplanningComponent } from './readiness/financialplanning/financialplanning.component';
import { ConfirmComponent } from './shared/confirm/confirm.component';
import { QuestionairrelibraryComponent } from './readiness/questionairrelibrary/questionairrelibrary.component';
import { CreateQuestionComponent } from './readiness/questionairrelibrary/create-question/create-question.component';

// import { AdalService, AdalInterceptor } from 'adal-angular4';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { checkDirtyState } from './readiness/questionairrelibrary/checkFormDirtyState';
import { NotesComponent } from './readiness/notes/notes.component';
import { QuestionStepperComponent } from './readiness/questionnaireclientview/question-stepper/question-stepper.component';
import { QuestionComponent } from './readiness/questionnaireclientview/question/question.component';
import { ScrollSpyDirective } from './directive/scroll-spy.directive';
import { OverviewApproachComponent } from './readiness/reports/overview-approach/overview-approach.component';
import { SummaryKeyfindingsComponent } from './readiness/reports/summary-keyfindings/summary-keyfindings.component';
import { KeyActivitiesComponent } from './readiness/reports/key-activities/key-activities.component';
import { ExportpptComponent } from './readiness/reports/exportppt/exportppt.component';
import { QuestionSummaryComponent } from './readiness/reports/question-summary/question-summary.component';
import { CreateKeyactivityComponent } from './readiness/reports/key-activities/create-keyactivity/create-keyactivity.component';
import { CreateNotesComponent } from './readiness/notes/create-notes/create-notes.component';
import { CreateUserComponent } from './users/create-user/create-user.component';
import { SharedModule } from './shared/shared.module';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { LogoutComponent } from './logout/logout.component';
import { AddnoteComponent } from './readiness/notes/addnote/addnote.component';
import { CreateFunctionalareaComponent } from './functionalarea/create-functionalarea/create-functionalarea.component';
import { SettingsProvider } from './settingsProvider';
// import {HttpClientModule} from '@angular/common/http';
import { GridactionrendererComponent } from './shared/gridactionrenderer/gridactionrenderer.component';
// tslint:disable-next-line:max-line-length
import { MultiSelectModule } from 'primeng/multiselect';
import { ListboxModule } from 'primeng/listbox';
// tslint:disable-next-line:max-line-length
import { QuestionnaireLibraryMasterComponent } from './readiness/questionairrelibrary/questionnairelibrary-master/questionnairelibrary-master.component';
// tslint:disable-next-line: max-line-length
import { QuestionnaireLibraryGridRendererComponent } from '../app/readiness/questionairrelibrary/questionnairelibrary-master/questionnairelibrary-master-gridcellrenderer.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { PubSubService } from './_services/pubsub.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgChartsModule } from 'ng2-charts';
// tslint:disable-next-line: max-line-length
import { ResponseDoughnutChartComponent } from './readiness/questionnaireclientview/question/charts/response-doughnut-chart/response-doughnut-chart.component';
// tslint:disable-next-line: max-line-length
import { ImpactStackedbarChartComponent } from './readiness/questionnaireclientview/question/charts/impact-stackedbar-chart/impact-stackedbar-chart.component';
// tslint:disable-next-line: max-line-length
import { EffortStackedbarChartComponent } from './readiness/questionnaireclientview/question/charts/effort-stackedbar-chart/effort-stackedbar-chart.component';
import { CreateOwnerComponent } from 'src/app/readiness/dashboard/create-owner/create-owner.component';
import { BenchmarkService } from './_services/benchmark.service';
import { OwnerService } from './_services/owner.service';
import { CopyEngagementComponent } from './readiness/questionairrelibrary/copy-engagement/copy-engagement.component';
// import {onPremises } from './../assets/client-config.json';
import { NonAdalInterceptor } from './_services/nonadal.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { HeatmapComponent } from './readiness/dashboard/heatmap/heatmap.component';
import { FunctionalareareadinessComponent } from './readiness/dashboard/functionalareareadiness/functionalareareadiness.component';
import { HeatmapGridRendererComponent } from './readiness/dashboard/heatmap/heatmap-gridcellrenderer.componenet';
import { UpdateHeatmapComponent } from './readiness/dashboard/heatmap/update-heatmap/update-heatmap.component';
import { MotifModule, MotifModalModule } from '@ey-xd/ng-motif';
import { ResetConfirmationComponent } from './readiness/questionairrelibrary/reset-engagement/reset.component';
import { ResetProjectComponent } from './readiness/questionairrelibrary/reset-project/reset-project.component';
import { DeletePopupComponent } from './workspace/delete-workspace/delete-workspace.component';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './factory';
import { environment } from 'src/assets/client-config';
import { RenewSessionComponent } from './session_management/components/renew-session/renew-session.component';
import { SessionInterceptor } from './session_management/session.interceptor';
import { SessionService } from './session_management/session-service.service';
import { QuestionUploadComponent } from './question-upload/question-upload.component';
import { ReadinessChartComponent } from './readiness/dashboard/functionalareareadiness/components/readiness-chart/readiness-chart.component';
import { GridToggleComponent } from './readiness/dashboard/functionalareareadiness/components/grid-toggle/grid-toggle.component';

import { MotifTreeModule } from '@ey-xd/ng-motif';
import { FaNameComponent } from './readiness/dashboard/functionalareareadiness/components/fa-name/fa-name.component';
import { ReadinessLayoutComponent } from './readiness/readiness-layout/readiness-layout.component';
import { OwnerTemplateComponent } from './readiness/dashboard/functionalareareadiness/components/owner-template/owner-template.component';
import { NoteTemplateComponent } from './readiness/notes/components/note-template/note-template.component';
import { DateCreatedComponent } from './readiness/notes/components/date-created/date-created.component';
import { ActionComponent } from './readiness/notes/components/action/action.component';
import { TemplateSelectionComponent } from './readiness/reports/template-selection/template-selection.component';


import { MotifAccordionModule } from '@ey-xd/ng-motif';
import { SelectRendererComponent } from './readiness/questionairrelibrary/rendering-elements/dropdownRender/select-render.component';
import { CheckBoxInScopeRendererComponent } from './readiness/questionairrelibrary/rendering-elements/checkboxInScope/checkbox-inscope-render.component';
import { CheckBoxPPTRendererComponent } from './readiness/questionairrelibrary/rendering-elements/checkboxPPT/checkbox-ppt-render.component';
import { ActionButtonRendererComponent } from './readiness/questionairrelibrary/rendering-elements/ActionButtonRender/action-button-render.component';
import { MotifCommentModule } from '@ey-xd/ng-motif';
import { CommentBoxComponent } from './readiness/questionnaireclientview/question/comment-box/comment-box/comment-box.component';
import { KeyActivitiesGridRendererComponent } from './readiness/reports/key-activities/key-activities-gridcellrenderer.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';


export function init(settingsProvider: SettingsProvider) {
  return () => settingsProvider.loadConfig();
}

export function getAlertConfig(): TooltipConfig {
  const is_touch_device = 'ontouchstart' in window;
  return Object.assign(new TooltipConfig(), {
    triggers: 'hover'
  });
}


@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,

    // DashboardComponent,
    // ReportsComponent,
    CreateWorkspaceComponent,
    // FinancialplanningComponent,
    // QuestionairrelibraryComponent,
    // CreateQuestionComponent,
    // NotesComponent,
    // QuestionStepperComponent,
    // QuestionComponent,
    ScrollSpyDirective,
    // OverviewApproachComponent,
    // SummaryKeyfindingsComponent,
    // KeyActivitiesComponent,
    // ExportpptComponent,
    // QuestionSummaryComponent,
    // CreateKeyactivityComponent,
    // CreateNotesComponent,
    CreateUserComponent,
    UnauthorizedComponent,
    NotfoundComponent,
    LogoutComponent,
    // AddnoteComponent,
    // CreateFunctionalareaComponent,
    // QuestionnaireSetupHeaderComponent,
    // QuestionnaireLibraryMasterComponent,
    // QuestionnaireLibraryGridRendererComponent,
    // ResponseDoughnutChartComponent,
    // ImpactStackedbarChartComponent,
    // EffortStackedbarChartComponent,
    // CreateOwnerComponent,
    // CopyEngagementComponent,
    // FunctionalareareadinessComponent,
    // HeatmapComponent,
    // HeatmapGridRendererComponent,
    // UpdateHeatmapComponent,
    RenewSessionComponent,
    // ResetConfirmationComponent,
    // ResetProjectComponent,
    DeletePopupComponent,
    QuestionUploadComponent,
    NoteTemplateComponent,
    DateCreatedComponent,
    ActionComponent,
    ActionButtonRendererComponent,
    SelectRendererComponent,
    CheckBoxPPTRendererComponent,
    CheckBoxInScopeRendererComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    BsDropdownModule.forRoot(),
    RouterModule.forRoot(appRoutes, {}),
    BrowserAnimationsModule,
    HttpClientJsonpModule,
    ModalModule.forRoot(),
    NgxSpinnerModule,
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    CollapseModule.forRoot(),
    DragDropModule,
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    MatStepperModule,
    MatIconModule,
    ButtonsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    QuillModule.forRoot(),
    AgGridModule,
    // AgGridModule.withComponents([GridactionrendererComponent, QuestionnaireLibraryGridRendererComponent, HeatmapGridRendererComponent,
    //   ActionButtonRendererComponent,
    //   SelectRendererComponent,
    //   CheckBoxPPTRendererComponent,
    //   CheckBoxInScopeRendererComponent,
    //   KeyActivitiesGridRendererComponent
    // ]),
    MultiSelectModule,
    ListboxModule,
    ScrollingModule,
    NgChartsModule,
    MsalModule.forRoot(MSALInstanceFactory(), MSALGuardConfigFactory(), MSALInterceptorConfigFactory()),
    MotifModule,
    MotifModalModule,
    MotifTreeModule,
    MotifAccordionModule,
    MotifCommentModule
  ],
  providers: [
    ErrorInterceptorProvider,
    AuthGuard,
    EngagementGuard,
    LoggingService,
    PubSubService,
    BenchmarkService,
    OwnerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true
    },
    {
      provide: 'canDeactivate',
      useValue: checkDirtyState
    },
    SettingsProvider,
    CookieService,
    SessionService,
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
