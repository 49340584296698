import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { Country } from '../models/country';
import { Industry } from '../models/industry';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/internal/operators/tap';
import { Exchange } from '../models/exchange';
import { environment } from 'src/assets/client-config';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private countryUrl = environment.apiUrl + '/api/Country/';
  private industryUrl = environment.apiUrl + '/api/Industry/';
  private exchangeUrl = environment.apiUrl + '/api/Exchange/';

  public isLoading = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) { }

  getCountry(): Observable<Country[]> {
    const url = environment.apiUrl + '/api/Country/GetOrderedOriginCountry/';
    return this.http.get<Country[]>(url).pipe(tap(data => data));
  }

  getCountryUnordered(): Observable<Country[]> {
    return this.http.get<Country[]>(this.countryUrl).pipe(tap(data => data));
  }

  getIndustry(): Observable<Industry[]> {
    const url = environment.apiUrl + '/api/Industry/GetIndustries/';
    return this.http.get<Industry[]>(url).pipe(tap((data) => data));
  }

  getExchange(): Observable<Exchange[]> {
    return this.http.get<Exchange[]>(this.exchangeUrl).pipe(tap((data) => data));
  }

  getAllDataForEditQuestionLibrary() {
    const countryurl = environment.apiUrl + '/api/Country/GetOrderedOriginCountry/';
    const allCountry = this.http.get<Country[]>(countryurl).pipe(tap(data => data));
    const allIndustry = this.http
      .get<Industry[]>(this.industryUrl)
      .pipe(tap((data) => data));
    const allExchange = this.http
      .get<Exchange[]>(this.exchangeUrl)
      .pipe(tap((data) => data));
    return forkJoin([allCountry, allIndustry, allExchange]);
  }
}
