import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { QuestionBuilder, QuestionMetadata, FunctionalArea } from './questionMetadata';
import { environment } from 'src/assets/client-config';

@Injectable({
  providedIn: 'root'
})
export class QuestionMetadatService {
  questionBuilders = new BehaviorSubject<any>([]);
  private questionBuildApiUrl = environment.apiUrl + '/api/QuestionMetadata/';

  constructor(private http: HttpClient) { }

  getQuestionBuilderList(): Observable<QuestionBuilder> {
    return this.http
      .get<QuestionBuilder>(this.questionBuildApiUrl + 'GroupByFunctionalArea/')
      .pipe(tap((data) => data), catchError(this.handleError<QuestionBuilder>('getQuestionBuilderList')));
  }

  updateQuestionMetadataList(functionalAreaQuestionsList: FunctionalArea[]): Observable<FunctionalArea[]> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http
      .put<FunctionalArea[]>(
        this.questionBuildApiUrl + 'UpdateQuestionMetadataList/',
        functionalAreaQuestionsList,
        options
      )
      .pipe(catchError(this.handleError<FunctionalArea[]>('updateQuestionMetadataList')));
  }

  UpdateFuncAreaQuesAutosave(
    functionalAreaQuestions: FunctionalArea
  ): Observable<FunctionalArea> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.put<FunctionalArea>(
      this.questionBuildApiUrl + 'UpdateFuncAreaQuesAutosave/',
      functionalAreaQuestions,
      options
    );
  }

  updateQuestionDescription(
    questionMetadata: QuestionMetadata
  ): Observable<QuestionMetadata> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.put<QuestionMetadata>(
      this.questionBuildApiUrl + 'UpdateQuestionDescription/',
      questionMetadata,
      options
    );
  }


  deleteQuestionMetadata(id: number) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.delete<number>(this.questionBuildApiUrl + id, options);
  }

  resetEngagementTables() {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<string>(this.questionBuildApiUrl + 'ResetEngagementTables', options);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result);
    };
  }

  moveQuestionToSetup(ids: any) {
    const url = this.questionBuildApiUrl + 'AddQuestionToSetup';
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<QuestionMetadata[]>(url, ids, options);
  }

  copyEngagement(engagementId: any) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<any>(
      this.questionBuildApiUrl + 'CopyEngagement/' + engagementId,
      options
    );
  }
}
