import { Component, Inject } from '@angular/core';
import { MOTIF_MODAL_DATA, MotifModal } from '@ey-xd/ng-motif';
import { WorkspaceService } from 'src/app/workspace/workspace.service';
import { IconoirXmark } from '@ey-xd/motif-icon';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent {
  title: string;
  content: any;
  confirmBtnName: string;
  cancelBtnName: string;
  isDeleteButton = false;
  popupOpen:boolean = false;
  btnColor: string = '';

  constructor(
    public modal: MotifModal,
    private workspace: WorkspaceService,
    @Inject(MOTIF_MODAL_DATA) motifData: any,
  ) {
    this.title = motifData?.initialState.title;
    this.content = motifData?.initialState.content;
    this.confirmBtnName = motifData?.initialState.confirmBtnName;
    this.cancelBtnName = motifData?.initialState.cancelBtnName;
    this.isDeleteButton = motifData?.initialState.isDeleteButton;
    this.btnColor = motifData?.initialState.btnColor;
    this.workspace.popupOpened.subscribe(item=>{
      this.popupOpen = item;
    })
    this.workspace.popupOpened.next(true);
  }

  confirmCancel(){
    this.workspace.routeChanged.next(false);
    this.workspace.popupOpened.next(true);
  }

  closePopup(){
    this.workspace.popupOpened.next(false)
  }

  closeIcon() {
    return IconoirXmark;
  }
}
